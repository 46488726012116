var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game-type4 my-2", attrs: { id: "game-" + _vm.data.block } },
    [
      _c(
        "swiper",
        {
          ref: "gameItem",
          staticClass: "mySwiper px-4 py-0",
          attrs: { options: _vm.swiperOption }
        },
        _vm._l(_vm.data.data.navbar, function(item) {
          return _c(
            "swiper-slide",
            { key: item._id, staticStyle: { width: "auto" } },
            [
              _c(
                "div",
                {
                  staticClass: "game__item--card cursor-pointer rounded",
                  class: [item.class + "-bg"],
                  on: {
                    click: function($event) {
                      return _vm.goVendorPage(item._id)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "game-card h-56px d-flex justify-center flex-column align-center"
                    },
                    [
                      _vm.navIcon(item)
                        ? _c("img", {
                            attrs: {
                              src: _vm.baseImgUrl + _vm.navIcon(item),
                              height: "26"
                            }
                          })
                        : _c("Icon", {
                            staticClass: "bg--text",
                            attrs: {
                              data: _vm.icons[item.class],
                              width: "20",
                              height: "26"
                            }
                          }),
                      _c("span", { staticClass: "caption bg--text" }, [
                        _vm._v(_vm._s(item.game_type))
                      ])
                    ],
                    1
                  )
                ]
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }